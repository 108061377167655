<!-- 用户状态标签 -->
<template>
  <div>
    <crud ref="crud" :config="config" @handle1="handleReport"></crud>
  </div>
</template>


<script>
import Crud from '@/components/crud/Crud'

export default {
  name: "index",
  components: {
    Crud
  },
  data() {
    return {
      config: {
        search: [
          { field: 'name', name: '名称' },
        ],
        button: [
          { name: '新增', icon: 'plus', event: 'add', color: 'primary' }
        ],
        table: {
          api: '/dynamic/dynamic-graph-category/list',
          deleteApi: '/dynamic/dynamic-graph-category/delete',
          col: [
            { type: 'index' },
            { field: 'name', name: '名称' },
            { field: 'sort', name: '排序' },
            { field: 'enableFlag', name: '启用状态', type: 'switch', api: '/userStatusTag/switchEnable' },
            { field: 'createTime', name: '创建时间', type: 'datetime' },
            {
              type: 'button', button: [
                { name: '编辑', event: 'edit' },
                { name: '删除', event: 'delete', checkShow: (row) => !(row.children && row.children.length) }
              ]
            },
          ]
        },
        form: {
          addApi: `/dynamic/dynamic-graph-category/add`,
          editApi: `/dynamic/dynamic-graph-category/edit`,
          item: [
            { field: 'name', name: '名称', required: true },
            { field: 'pid', name: '上级', type: 'select', api: '/dynamic/dynamic-graph-category/selectList', reload: true },
            { field: 'sort', name: '序号', type: 'number', min: 0 },
          ]
        }
      },
    }
  },
}
</script>